
              @import "@/assets/css/variables.scss";
            






































// @import '@/assets/css/pages/PUGContactUs.scss';
.contact_desc {
  font-size: 14px;
  color: $text-secondary;
  padding-bottom: 16px;
}
.contact_box {
  display: flex;
  align-items: center;
  padding: 24px;
  background-color: $background-color-base;
  border-radius: 8px;
  margin-bottom: 16px;
  @include screen-mobile {
    flex-direction: column;
    padding: 16px;
  }
  .contact_l_item {
    display: flex;
    align-items: center;
    width: 33.3%;
    @include screen-mobile {
      width: 100%;
      margin-bottom: 16px;
    }
    &.social_box {
      justify-content: flex-end;
      @include screen-mobile {
        justify-content: flex-start;
        margin-bottom: 0px;
      }
    }
    .item_icon {
      width: 24px;
      @include rtl-sass-prop(margin-right, margin-left, 8px);
    }
    .content {
      color: $secondary;
      a {
        text-decoration: underline;
      }
    }
  }
}
.map_box {
  background-color: $background-color-base;
  border-radius: 8px;
}
